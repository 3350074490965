<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="6">
          <el-form-item label="资源编码">
            <el-input v-model="searchForm.resourceCode" placeholder="请输入 资源编码"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="回传类型">
            <el-select clearable v-model="searchForm.rebackDataType" placeholder="请选择" size="large">
              <el-option v-for="item in options" :key="item.code" :label="item.name" :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="设备IP">
            <el-input v-model="searchForm.clientIp" placeholder="请输入 设备IP"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="设备码">
            <el-input v-model="searchForm.physicalAdrress" placeholder="请输入 设备码"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="工作状态">
            <el-select clearable v-model="searchForm.workStatus" placeholder="请选择" size="large">
              <el-option v-for="item in workOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="时间范围">
            <el-date-picker v-model="time" value-format="YYYY-MM-DD HH:mm:ss" type="datetimerange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </el-form-item>
        </el-col>
      </template>
    </Searchbar>

    <basicTable
      @currentChange="handleCurrentChange"
      @sizeChange="handleSizeChange"
      :tableData="tableData"
      :attrList="attrList"
      :page="page.current"
      :limit="page.limit"
      :total="page.total">
    </basicTable>

    <el-dialog
      v-model="isShow"
      title="详情"
      :close-on-click-modal="false"
    >
      <el-form label-position="right" size="small" label-width="120px">
        <el-row :gutter="20">
          <el-col :span="12" v-for="(item,index) in form" :key="index">
            <el-form-item :label="item.title" prop="time_length">
              <div style="line-height: 50px;text-align: left">{{ item.body }}</div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { toRefs, ref, reactive, onMounted } from "vue";
import Searchbar from "@/components/Searchbar";
import { getData, getDetail } from "@/api/terminalRegulate/check";
import { getNowTime } from "../../util/date";
import { codeInfo } from "@/api/devops/strategy";

export default {
  components: {
    Searchbar
  },
  setup() {
    function onSearch() {
      if (state.time.length > 0) {
        state.searchForm["startTime"] = state.time[0];
        state.searchForm["endTime"] = state.time[1];
      }
      onLoad(state.searchForm);
    }

    onMounted(() => {
      onLoad();
      codeInfo({ type: 6 }).then(res => {
        state.options = res.data.data;
      });
    });

    function onLoad(form = {}) {
      getData({ limit: state.page.limit, page: state.page.current, ...form }).then(res => {
        state.tableData = res.data.data.list;
        state.page.total = res.data.data.count;
      });
    }

    function handleCurrentChange(currentPage) {
      state.page.current = currentPage;
      onLoad(state.searchForm);
    }

    function handleSizeChange(pageSize) {
      state.page.limit = pageSize;
      onLoad(state.searchForm);
    }

    let isShow = ref(false);
    let state = reactive({
      time: [],
      options: [],
      workOptions:[
        {
          value:0,
          label: "离线"
        },
        {
          value:1,
          label: "空闲"
        },
        {
          value:2,
          label: "工作"
        }
      ],
      searchForm: {},
      form: {},
      page: {
        current: 1,
        limit: 10,
        total: 0
      },

      tableData: [],

      attrList: [
        {
          prop: "resourceCode",
          label: "资源编码",
          render: (row) => <div>{row.resourceCode}</div>
        },
        {
          prop: "rebackType",
          label: "上报类型",
          render: (row) => <div>{row.rebackType}</div>
        },
        {
          prop: "rebackDataTypeName",
          label: "回传数据类型名称",
          render: (row) => <div>{row.rebackDataTypeName}</div>
        },
        {
          prop: "clientIp",
          label: "设备ip",
          render: (row) => <div>{row.clientIp}</div>
        },
        {
          prop: "time",
          label: "回传时间",
          render: (row) => <div>{row.time}</div>
        },
        {
          prop: "",
          label: "操作",
          render: (row) => <el-button type="text" icon="View" onClick={() => view(row)}>详情</el-button>
        }
      ]
    });

    function view(row) {
      getDetail({ id: row.id }).then(res => {
        let data = JSON.parse(res.data.data.dataBody);
        state.form = [];
        isShow.value = true;
        for (let i in data) {
          state.form.push({
            title: i,
            body: data[i]
          });
        }
      });
    }

    function resetForm() {
      state.searchForm = {};
      onLoad(state.searchForm);
    }

    return {
      resetForm,
      onSearch,
      isShow,
      ...toRefs(state),
      handleCurrentChange,
      handleSizeChange
    };
  }
};
</script>

<style scoped lang="less">
::v-deep .el-form-item__content .el-date-editor {
  height: 100% !important;

  .el-range-separator {
    line-height: 40px;
  }
}
</style>