import axios from "@/api/axios"; // 引用axios
//音量调节
export const getData = (params) => {
    return axios({
        url: "/api/rebackData/listData",
        method: "post",
        params: params,
    });
};

export const getDetail = (params) => {
    return axios({
        url: "/api/rebackData/detail",
        method: "post",
        params: params,
    });
};

